@font-face {
  font-family: 'proxima nova';
  font-weight: 100;
  src: url('../assets/fonts/Proxima Nova Thin.otf');
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 200;
  src: url('../assets/fonts/Proxima Nova Thin.otf');
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 300;
  src: url('../assets/fonts/Proxima Nova Thin.otf');
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 400;
  src: url('../assets/fonts/ProximaNova-Regular.otf');
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 500;
  src: url('../assets/fonts/Proxima Nova Bold.otf');
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 600;
  src: url('../assets/fonts/Proxima Nova Bold.otf');
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 700;
  src: url('../assets/fonts/Proxima Nova Extrabold.otf');
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 800;
  src: url('../assets/fonts/Proxima Nova Extrabold.otf');
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 900;
  src: url('../assets/fonts/Proxima Nova Black.otf');
}
