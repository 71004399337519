@import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fe;
}
#okta-sign-in.auth-container.main-container {
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #a0a3bd22;
}
#okta-sign-in.auth-container .okta-sign-in-header {
  display: none;
  border: 0;
}

#okta-sign-in.auth-container .button-primary {
  color: #171717;
  background: #fed44f;
  border-color: #fed44f;
}
#okta-sign-in.auth-container .button-primary:hover {
  color: #171717;
  background: #fed44fbb;
  border-color: #fed44fbb;
}

#okta-sign-in.auth-container .okta-form-input-field {
  background-color: #eff0f6;
  border: 0;
}

#okta-sign-in.auth-container .okta-form-input-field :focus {
  background-color: #eff0f6;
  border-color: #c4c8f3 !important;
  outline: none;
}
