@import '~antd/dist/antd.less';
@import './fonts.css';
@import './CustomScrollbar.css';
@import './CustomTabBar.css';
@import './CustomTheme.less';

.ant-skeleton-content .ant-skeleton-title {
  margin: 0;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 10px;
}
ul.ant-skeleton-paragraph {
  margin-bottom: 0em;
}

.ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-input-password,
.ant-select-selector {
  background-color: #eff0f6 !important;
  color: black !important;
  border: 0 !important;
  box-shadow: none !important;

  &[disabled] {
    border: 0;
    color: black;
    background-color: transparent !important;
    cursor: initial;
  }
}
.ant-input-number-disabled {
  border: 0;
  color: black;
  background-color: transparent !important;
  cursor: initial;
}
.ant-select-selector {
  background-color: #eff0f6 !important;
  border: 0 !important;
  border-radius: 5px;
  box-shadow: none !important;
  color: '#171717';
  width: 100%;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: #eff0f6;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: #eff0f6;
}

.ant-layout-content {
  background-color: #fff;
}
* {
  font-family: 'proxima nova';
}

.ant-card-head {
  height: 12%;
}
.ant-card-body {
  height: 88%;
}

.ant-tooltip-inner {
  color: #171717 !important;
}