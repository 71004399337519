::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: #f5f7fe;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bcc1df;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c5cae3;
}
